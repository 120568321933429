<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="2" class="container-padding">
        <h2 class="titulo text-center">Reporte de personal</h2>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-container fluid fill-height class="text-center">
      <v-row>
        <organization-chart :datasource="ds">
          <template slot-scope="{ nodeData }">
            <div :class="['node-box', nodeData.id == '1' ? 'parent' : '']">
              <div class="node-title">{{ (nodeData.nombre ? nodeData.nombre : "") + (nodeData.apellido_paterno ? nodeData.apellido_paterno : "") + (nodeData.apellido_materno ? nodeData.apellido_materno : "") }}</div>
              <div class="node-content" v-for="(rol, i) in nodeData.roles" :key="i">
                {{ rol.rol }}
              </div>
            </div>
          </template>
        </organization-chart>
      </v-row>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import "animate.css";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import services from "@/utils/services";
import _ from "lodash";

export default {
  name: "personal-report",
  components: {
    OrganizationChart,
  },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    modulos: [],
    items: [],
    ds: {
      id: "1",
      nombre: "Money In CaSH",
      children: [],
    },
    logo: require("@/assets/logo.png"),
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.user + "/personalReport")
        .then((response) => {
          this.items = response.data;
          this.searchGroups("idcoordinador");
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async searchGroups(key, i) {
      const that = this;
      for (let value of this.items.grupos) {
        let info = _.filter(this.items.usuarios, function (o) {
          let valid = false;
          if (value[key] == o.idusuario && o.roles.length > 0) {
            if (key == "idsupervisor") {
              let validGroup = false;
              for (let group of o.grupos) {
                if (group.idgrupo == that.ds.children[i].idgrupo) {
                  validGroup = true;
                }
              }
              if (validGroup) {
                valid = true;
              }
            } else if (key == "idcoordinador") {
              valid = true;
            }
          }
          return valid;
        });
        if (info.length > 0) {
          info[0].idgrupo = value.idgrupo;
          info[0].children = [];
          if (key == "idcoordinador") {
            this.ds.children.push(info[0]);
          }
          if (key == "idsupervisor") {
            this.ds.children[i].children.push(info[0]);
          }
        }
      }
      if (key == "idcoordinador") {
        this.thirdLevel();
      }
    },
    async thirdLevel() {
      this.searchGroups("idsupervisor", 7);
    },
  },
  fourLevel() {
    let auxArray = [];
    for (let value of this.items.grupos) {
      for (let i = 0; i < this.items.usuarios.length; i++) {
        for (let j = 0; j < this.ds.children.length; j++) {
          for (let k = 0; k < this.ds.children[j].children.length; k++) {
            if (value["idencargado"] == this.items.usuarios[i].idusuario && (this.items.usuarios[i].grupos.length > 0 ? this.ds.children[j].children[k].idgrupo == this.items.usuarios[i].grupos[0].idgrupo : false)) {
              this.ds.children[j].children[k].children.push(this.items.usuarios[i]);
            }
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.orgchart {
  background: #000;
}
.node-box {
  border: 1px solid #222665;
  color: white;
  position: relative;
  &.parent::before {
    content: "";
    display: none;
  }
}
.node-title {
  background: #222665;
}
.node-content {
  background-color: #fff;
  color: #000;
}
.node-box::before {
  content: "▼";
  position: absolute;
  top: -16px;
  left: 50%;
  color: #ed860e;
  margin-left: -8px;
}
</style>
